var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('WidgetsDropdown',{attrs:{"header1":_vm.state_status['act'],"title1":"Active","header2":_vm.state_status['rec'],"title2":"Recovered","header3":_vm.state_status['conf'],"title3":"Confirmed","header4":_vm.state_status['dec'],"title4":"Deaths","dataPoints1":_vm.dist_status['act'],"dataPoints2":_vm.dist_status['con'],"dataPoints3":_vm.dist_status['rec'],"dataPoints4":_vm.dist_status['dec']}}),_c('CCard',[_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"sm":"5"}},[_c('h4',{staticClass:"card-title mb-0",attrs:{"id":"traffic"}},[_vm._v("Covid Spreading Among Districts")]),_c('div',{staticClass:"small text-muted"},[_vm._v("November 2017")])]),_c('CCol',{staticClass:"d-none d-md-block",attrs:{"sm":"7"}},[_c('CButton',{staticClass:"float-right",attrs:{"color":"primary"}},[_c('CIcon',{attrs:{"name":"cil-cloud-download"}})],1)],1)],1),_c('StateChart',{staticStyle:{"height":"300px","margin-top":"40px"},attrs:{"labels":_vm.dist_status['name'],"active":_vm.dist_status['act'],"confirmed":_vm.dist_status['con'],"recovered":_vm.dist_status['rec']}})],1)],1),_c('CCardGroup',{staticClass:"card-columns cols-2",attrs:{"columns":""}},[_c('CCard',[_c('CCardHeader',[_vm._v(" Infectted V/s Recoved ")]),_c('CCardBody',[_c('CChartDoughnut',{attrs:{"datasets":[
        {
          backgroundColor: [
            '#41B883',
            '#E46651',
            '#00D8FF',
            '#DD1B16'
          ],
          data: [_vm.state_status['conf'],_vm.state_status['rec']]
        }
      ],"labels":[ 'Positive', 'Recovered' ]}})],1)],1),_c('CCard',[_c('CCardHeader',[_vm._v(" Infectted V/s Deceased ")]),_c('CCardBody',[_c('CChartPie',{attrs:{"datasets":[
        {
          backgroundColor: [
            '#41B883',
            '#E46651',
            '#00D8FF',
            '#DD1B16'
          ],
          data: [_vm.state_status['conf'],_vm.state_status['dec']]
        }
      ],"labels":[ 'Positive', 'Deaceased' ]}})],1)],1)],1),_c('CCol',{attrs:{"sm":"12"}},[_c('FTable',{attrs:{"items":_vm.getShuffledUsersData(),"hover":"","striped":"","border":"","small":"","fixed":"","dark":"","caption":"District infection Statistics"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }